var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('mtop'),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('m.ljqb')))]),_c('div',{staticClass:"list",class:_vm.show ? 'h5' : ''},[_c('div',{staticClass:"item",on:{"click":_vm.wallet}},[_c('div',{staticClass:"left"},[_vm._v("Metamask")]),_vm._m(0)]),_c('div',{staticClass:"item",on:{"click":_vm.towallet}},[_c('div',{staticClass:"left"},[_vm._v("WalletConnect")]),_vm._m(1)]),_c('div',{staticClass:"item",on:{"click":_vm.towalletTp}},[_c('div',{staticClass:"left"},[_vm._v("TokenPocket")]),_vm._m(2)]),_c('div',{staticClass:"item",on:{"click":_vm.towallet}},[_c('div',{staticClass:"left"},[_vm._v("ImToken")]),_vm._m(3)]),_c('div',{staticClass:"item",on:{"click":_vm.towalletBk}},[_c('div',{staticClass:"left"},[_vm._v("BitKeep")]),_vm._m(4)]),_c('div',{staticClass:"item",on:{"click":_vm.towallet}},[_c('div',{staticClass:"left"},[_vm._v("MathWallet")]),_vm._m(5)]),_c('div',{staticClass:"item",on:{"click":_vm.towallet}},[_c('div',{staticClass:"left"},[_vm._v("TrustWallet")]),_vm._m(6)])]),_c('mbottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/img/my-nft/metamask.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/img/my-nft/masck.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/img/my-nft/ic2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/img/my-nft/ic1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/img/my-nft/ic6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/img/my-nft/ic3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../assets/img/my-nft/ic4.png"),"alt":""}})])
}]

export { render, staticRenderFns }