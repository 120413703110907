<template>
  <van-popup
    v-model="shows"
    round
    @close="close"
    :close-on-click-overlay="false"
    :closeable="!loading"
  >
    <div class="popup">
      <div class="stop">
      <div class="title">{{$t('m.guanyunt')}}</div>
      </div>
      <div class="box">
        <div class="p">{{ $t('m.guanyu1') }}</div>
        <div class="p">{{ $t('m.guanyu2') }}</div>
        <div class="p">{{ $t('m.guanyu3') }}</div>
      </div>    
      <img src="../assets/img/nt.png" alt="" srcset="" class="her" v-if="dqyy=='zh-CN'">
      <img src="../assets/img/nte.png" alt="" srcset="" class="her" v-else>
    </div>
  </van-popup>
</template>

<script>
import {  Img } from "@/api/Public";
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      myFht: 0,
      myTrx: 0,
      shows: false,
      free: 0,
      value: 75,
      active: 1,
      sqnum: 0,
      reshash:'',
      resID: {
        hase:''
      },
      nftimg:Img,
      task: "",
      hase: '',
      issq: false,
      currentDate:new Date(),
      formTime: '',
      toTime:'',
      tshow: false,
      tshows: false,
      detail:''
      
    };
  },
  watch: {
    show(val) {
      this.shows = val;
      if (val) {
         this.detail=''
      }
    },
  }, 
  computed: {
        dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    },
    ftime() {
         var dd = new Date();
            var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      return y+ '-' + m + '-' + d
     },
 },
  methods: {
    confirm(e) {
    
        var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.toTime = y + '-' + m + '-' + d
      this.tshow=false
     },
    confirms(e) {
         var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.formTime = y + '-' + m + '-' + d
      this.tshows=false
     },
    close() {
      this.$emit("tshow", false);
    },
    toclose() {
      this.active=1
      this.$emit("tshow", false);
      this.$emit("updata", true);
    },
    
    Upadata() {       
      if (!this.detail) {
     this.$emit("updata", '');
        return
      } 
      this.loading=true
        this.loading = false
       this.$emit("updata", this.detail);
    //   this.$post({
    //     url: "/dayReport",
    //     data: {
    //      content : this.detail,   
    //     },
    //     callback: (res) => {
    //       this.$nextTick(function () {
    //         this.loading = false;
    //         if (res.code == 200) {
    //           //  this.active=1
    //           this.$emit("tshow", false);
    //           this.$emit("updata", true);
    //         } else {
    //           this.$notify(res.msg);
    //         }
    //       });
    //     },
    //   });
    },    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.popup {
  width: 686px;
min-height: 345px;
background: #FFFFFF;
border-radius: 4px;

  .stop {
    height: 69px;
//    border-bottom: 1px solid #DCDEE0;
     .title {
    text-align: center;
    margin: 32px 0;
    font-size: 32px;
font-weight: 500;
color: #323233;
line-height: 69px;
  }
  }
 
  .box{
    padding: 29px 29px 10px 29px;
    .p{
        font-size: 24px;
font-weight: 400;
color: #7A7D85;
line-height: 42px;
text-align: left;
    }
    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px ;
      width: 552px;
height: 164px;
background: #F8F8F8;
border-radius: 12px;
margin: 10px auto;
padding: 24px;
box-sizing: border-box;
      .icon{
        width: 116px;
        height: 116px;
        border-radius: 100%;
      }
      .right{
       margin-left: 22px;
       flex: 1;
        text-align: left;
      font-size: 32px;
font-weight: 500;
color: #232428;
line-height: 44px;    
      
      }
    }
  }
  .her{
    width:100%
  }

   .smbottom {
    display: flex;
    align-items: center;
    justify-content: center;
        padding: 12px 0;
      .btn {
    font-size: 32px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 width: 310px;
height: 88px;
background: #1C9371;
border-radius: 12px;
border: 1px solid #1C9371;
font-weight: 400;
  }
      .qbtn {
        width: 200px;
height: 88px;
font-weight: 400;
     background: #FFFFFF;
border-radius: 12px;
border: 1px solid #1C9371;
color: #1C9371;
    }
  }
}
</style>
