<template>
  <div id="app">
    <transition name="slide-fade" mode="out-in">
      <router-view v-if="shows" />
      <div v-else class="jzzs">
        <div>
          <van-loading type="spinner" color="#000" />
        </div>
        <div>{{ tet }}</div>
      </div>
    </transition>
  </div>
</template>

<script>

window.onload = function () {
  // 禁用双指放大
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  // 禁用双击放大
  let lastTouchEnd = 0
  document.addEventListener(
    'touchend',
    function (event) {
      const now = new Date().getTime()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}
export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
      show: false,
      timer: false,
      screenWidth: document.body.clientWidth,
    };
  },
  data() {
    return {
      shows: true,
      tet: "loading ...",
    };
  },

  beforeCreate() {

  },
  mounted() {
    window.setKey=(e)=>{
      window.ntSends(e)
    }
    window.getUniAppMessage=(info)=>{

  let type=sessionStorage.getItem('type')
     if(type==1||type==2){
      window.homes(info)
       
     }else if(type==3){
      window.stakes(info)
     }else if(type==4){
      window.synthesis(info)
     }else if(type==5){
      window.nftSends(info)
     }else if(type==6){
      window.toSend(info)
     }else if(type==7){
      window.getNt(info)
     }

 },
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.show = this.screenWidth > 800 ? false : true;
        this.$store.commit("phone", this.show);
      })();
    };
  },

  created() {
    this.screenWidth = document.body.clientWidth;
    this.show = this.screenWidth > 800 ? false : true;
    this.$store.commit("phone", this.show);
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
    cread() {},
  },
};
</script>

<style lang="scss">
a {
  display: none;
}
#app {
  background: #f8f8f8 !important;

}
.main{
    margin: 0 auto;
  max-width: 750px;
  width: 100%;
  position: relative;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.van-toast {
  font-size: 26px !important;
  line-height: 26px !important;
  // background-color:#009270 !important;
  width: 80% !important;
}
#app {
  font-family: DINPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  width: 100%;
  overflow: hidden;
}
// @font-face {
//   font-family: Dinblack;
//   src: url("~@/assets/font/DIN-BLACK.otf");
// }
@font-face {
  font-family: DINPro;
  // src: url("~@/assets/font/DINPro-Bold.otf");
}
@font-face {
  font-family: MiSans;
  // src: url("~@/assets/font/Shree714.ttf");
}
.van-list__finished-text{
  font-size: 28px !important;
}
.van-stepper__input{
  font-size: 28px !important;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.van-popup__close-icon {
  font-size: 36px !important;
}
.van-notify {
  font-size: 36px !important;
  line-height: 50px !important;
}
// .van-notify--danger{
//   background: rgb(61 60 60 / 82%) !important;
// }
.van-popup--top {
  //   top: 50% !important;
  // left: 50% !important;
  // width: 80% !important;
  // transform: translate3d(-50%,-50%,0);
  // padding-left: 150px !important;
  // border-radius: 10px !important;
  // min-height: 100px !important;
}
.van-popup--top::before {
  content: "";
  //  background: url('../src/assets/img/nft.png') no-repeat;
  background-size: contain;
  height: 100px;
  width: 202px;
  position: absolute;
  z-index: 2005;
  left: 0;
  bottom: 0;
}
.jzzs {
  height: 100vh;
}
.jzzs div {
  padding-top: 20%;
  font-size: 23px;
  color: #2c3e50;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-duration: 0.8s;
  animation-name: fadeIn;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation-duration: 0.8s;
  animation-name: fadeInUp;
}

@keyframes fadeInDow {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDow {
  animation-duration: 0.8s;
  animation-name: fadeInDow;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(40px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  animation-duration: 0.8s;
  animation-name: fadeInRight;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-40px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  animation-duration: 0.8s;
  animation-name: fadeInLeft;
}
</style>
