<template>
  <van-popup
    v-model="shows"
    round
    @close="close"
    :close-on-click-overlay="false"
    :closeable="!loading"
  >
    <div class="popup">
      <div class="stop">
      <div class="title">{{$t('m.zhuzao')}}</div>
      </div>
       <div class="boxs">
        <div class="item">
          <img :src="nftimg" alt="" srcset="" class="icon">
          <div class="right">
           {{$t('m.v1nft')}}
          </div>
          <div class="dec">x{{ data.maxnum }}</div>
        </div>
      </div>
      <div class="box">
         <div class="tips" v-if="!isParent">{{$t('m.qsyyqrdz')}}</div>
        <div class="item" v-if="!isParent">
          <div class="right">
            <input type="text"  v-model="detail" :placeholder="$t('m.qtxdz')">
          </div>
        </div>   
         <div class="item" >
          <div class="right">
            <van-stepper v-model="buynum" :max="data.maxnum"  disable-input input-width="80px" :min="0"  button-size="32px"  class="mint" />
            <div class="btn" @click="buynum=data.maxnum">{{$t('m.max')}}</div>
          </div>
        </div>     
      </div>
      <div class="smbottom">
         <van-button
          :loading="loading||uniLoading"
          type="primary"
          @click="close"
          class="btn qbtn"
        >
          {{$t('m.klyx')}}</van-button
        >

        <van-button
          :loading="loading||uniLoading"
          type="primary"
          @click="Upadata"
          class="btn"
          loading-text="Loading..."
        >
          {{$t('m.lijizhuzao')}}</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
import {  ImgV1,toStake,getCost,sendTrans,getNoce } from "@/api/Public";
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      uniLoading:false,
      myFht: 0,
      myTrx: 0,
      shows: false,
      free: 0,
      value: 75,
      active: 1,
      sqnum: 0,
      reshash:'',
      resID: {
        hase:''
      },
      buynum:0,
       nftimg:ImgV1,
      task: "",
      hase: '',
      issq: false,
      currentDate:new Date(),
      formTime: '',
      toTime:'',
      tshow: false,
      tshows: false,
      detail: '',
      isParent:false
      
    };
  },
  watch: {
    show(val) {
      this.shows = val;
      if (val) {
        this.togetCost()
        this.detail = ''
         this.buynum=''
      }
    },
  },
  computed:{
    ftime() {
         var dd = new Date();
            var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      return y+ '-' + m + '-' + d
    },     
  
 },
 mounted(){
  window.stakes=(info)=>{
    this.handleTran(info)
  }
 },
  methods: {
   async  handleTran(info){
    this.uniLoading=true
     const res=await sendTrans(info)
     if(res.success){ 
      this.$notify({ message: this.$i18n.t("m.zhuzaochenggong"), type: "success" });
      this.$emit("updata", this.detail);
     }else{
      this.$notify(this.$i18n.t("m.zhhuzaoshibai"));
     }
     this.uniLoading=false
    },
    togetCost() { 
      getCost(this.$store.getters.userWallet).then(res => {
        this.isParent=res
      })
    },
    confirm(e) {
    
        var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.toTime = y + '-' + m + '-' + d
      this.tshow=false
     },
    confirms(e) {
         var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.formTime = y + '-' + m + '-' + d
      this.tshows=false
     },
    close() {
      this.$emit("tshow", false);
    },
    toclose() {
      this.active=1
      this.$emit("tshow", false);
      this.$emit("updata", true);
    },
    
   async Upadata() {    
    sessionStorage.setItem('type',3)
      let resp= await getNoce(this.$store.getters.userWallet)
     if(resp.success){
      uni.postMessage({
        data: {
          noce: resp.res
        
        }
      })
     }
      if (this.buynum > 0) {
       
        if (!this.isParent && !this.detail) { 
          this.$notify(this.$i18n.t("m.zhuzaoqingshurushangjidizhi"));
          return
        }
        if (this.detail.toUpperCase() == this.$store.getters.userWallet.toUpperCase()) { 
          this.$notify(this.$i18n.t("m.bukibangdingzijidizhi"));
          return
        }
         this.loading = true
        toStake(this.$store.getters.userWallet,this.buynum, this.detail).then(res => {
          this.loading = false
          if (res) {
            this.$notify({ message: this.$i18n.t("m.zhuzaochenggong"), type: "success" });
            this.$emit("updata", this.detail);
          } else {
            // this.$notify(this.$i18n.t("m.zhhuzaoshibai"));
          }
        })
      }
    //   this.$post({
    //     url: "/dayReport",
    //     data: {
    //      content : this.detail,   
    //     },
    //     callback: (res) => {
    //       this.$nextTick(function () {
    //         this.loading = false;
    //         if (res.code == 200) {
    //           //  this.active=1
    //           this.$emit("tshow", false);
    //           this.$emit("updata", true);
    //         } else {
    //           this.$notify(res.msg);
    //         }
    //       });
    //     },
    //   });
    },    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.popup {
  width: 686px;
min-height: 345px;
background: #FFFFFF;
border-radius: 4px;

  .stop {
    height: 69px;
//    border-bottom: 1px solid #DCDEE0;
     .title {
    text-align: center;
    margin-bottom: 32px;
    font-size: 32px;
font-weight: 500;
color: #323233;
line-height: 69px;
  }
  }
 .boxs{
    padding: 29px 0 0;
    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px ;
      width: 552px;
height: 164px;
background: #F8F8F8;
border-radius: 12px;
margin: 10px auto;
padding: 24px;
box-sizing: border-box;
      .icon{
        width: 116px;
        height: 116px;
        border-radius: 100%;
      }
      .right{
       margin-left: 22px;
       flex: 1;
        text-align: left;
      font-size: 32px;
font-weight: 500;
color: #232428;
line-height: 44px;    
      
      }
      .dec{
        font-size: 22px;
        color: #999999;
      }

    }
  }
  .box{
    padding: 29px 0 39px;

    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px ;
      .right{
        width: 80%;
        background: #FFFFFF;
        display:flex;
        align-items:center;
        justify-content: center;
        input{
          padding: 0 20px;
          width: 100%;
          box-sizing: border-box;
          height: 80px;
          font-size: 24px;
          color: #030304;
           border: 1px solid #DCDEE0;
          border-radius: 3px;
        }  
        .btn{
              font-size: 28px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 68px;
    margin: 10px;
 width: 110px;
height: 68px;
background: #1C9371;
border-radius: 12px;
border: 1px solid #1C9371;
font-weight: 400;
        }  
      
      }
    }
  }

   .smbottom {
    display: flex;
    align-items: center;
    justify-content: center;
        padding: 12px 0;
      .btn {
    font-size: 32px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 width: 310px;
height: 88px;
background: #1C9371;
border-radius: 12px;
border: 1px solid #1C9371;
font-weight: 400;
  }
      .qbtn {
        width: 200px;
height: 88px;
font-weight: 400;
     background: #FFFFFF;
border-radius: 12px;
border: 1px solid #1C9371;
color: #1C9371;
    }
  }
}
</style>
