<template>
  <div class="main">
    <mtop  />

        <div class="title">{{$t('m.ljqb')}}</div>
    <div class="list" :class="show ? 'h5' : ''">
      <div class="item" @click="wallet">
          <div class="left">Metamask</div>
          <div class="right">
              <img src="../assets/img/my-nft/metamask.png" alt="">
          </div>
      </div>
        <div class="item" @click="towallet">
          <div class="left">WalletConnect</div>
          <div class="right">
              <img src="../assets/img/my-nft/masck.png" alt="">
          </div>
      </div>
         <!-- <div class="item" @click="towallet">
          <div class="left">Coinbase</div>
          <div class="right">
              <img src="../assets/img/my-nft/ic5.png" alt="">
          </div>
      </div> -->
       
        <div class="item" @click="towalletTp">
          <div class="left">TokenPocket</div>
          <div class="right">
              <img src="../assets/img/my-nft/ic2.png" alt="">
          </div>
      </div>
         <div class="item" @click="towallet">
          <div class="left">ImToken</div>
          <div class="right">
              <img src="../assets/img/my-nft/ic1.png" alt="">
          </div>
      </div>
         <div class="item" @click="towalletBk">
          <div class="left">BitKeep</div>
          <div class="right">
              <img src="../assets/img/my-nft/ic6.png" alt="">
          </div>
      </div>
         <div class="item" @click="towallet">
          <div class="left">MathWallet</div>
          <div class="right">
              <img src="../assets/img/my-nft/ic3.png" alt="">
          </div>
      </div>
            <div class="item" @click="towallet">
          <div class="left">TrustWallet</div>
          <div class="right">
              <img src="../assets/img/my-nft/ic4.png" alt="">
          </div>
      </div>
    </div>
    
    <mbottom />
  </div>
</template>
<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import { connectTron ,connect,connectTp,connectBk} from "@/api/Public";
export default {
  components: {
    mtop,
    mbottom,
  },
  data() {
    return {
           show: false,
    };
  },
   mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
    watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },  
    deep: true,
    immediate: true,
  },
  methods:{
        _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    towalletBk(){
      connectBk().then(res=>{
        if(res){
         this.$store.commit("userWallet", res[0]);
            this.$store.commit("ltype", "4");
             this.login()}else{
              this.wallet()
             }
      })
    },
    towalletTp(){
      connectTp().then(res=>{
         if(res){
 this.$store.commit("userWallet", res[0]);
            this.$store.commit("ltype", "3");
             this.login()
         }else{
              this.wallet()
             }
      })
    },
     towallet(){
      connect().then(res=>{
        // console.log(res[0]);
        
         if(res[0]){
            this.$store.commit("userWallet", res[0]);
            this.$store.commit("ltype", "2");
             this.login()
         }

      })
    },
     wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                this.$store.commit("userWallet", info[0]);
                 this.$store.commit("ltype", "1");
                this.login()
               
              } else {
                this.$notify("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              // this.$notify(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },
    login(){      
                   this.$router.replace({ path: '/' });
          
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
    padding-top: 70px;
    background: #ffffff;
 .title{
  font-size: 40px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #192638;
line-height: 56px;
text-align: left;
margin: 80px auto 37px;
  width: 448px;
 }
 .list{
   margin-bottom: 62px;
         min-height: 60vh;
     .item{
  width: 448px;
height: 63px;
background: #F7F8FB;
 border: 2px solid #F7F8FB;
border-radius: 20px;
display: flex;
align-items: center;
justify-content: space-between;
margin: 20px auto;
padding: 0 23px;
cursor: pointer;
         .left{
             font-size: 15px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #192638;
line-height: 21px;
         }
         .right{
             width: 50px;
             height: 50px;
             font-size: 0;
              img{
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
         }
     }
     .item:hover,
     .item:active{
       border: 2px solid #F9B800;
       .left{
       color: #F9B800;
       }
     }
 }
 .h5{
     .item{
         width: 632px;
height: 100px;
border-radius:20px;
border: 2px solid #F7F8FB;
background: #F7F8FB;
display: flex;
align-items: center;
justify-content: space-between;
margin: 20px auto;
padding: 0 36px;
.left{
    font-size: 30px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #192638;
line-height: 42px;
}
.right{
    width: 78px;
    height: 78px;
    font-size: 0;
    img{
        width: 100%;
        height: 100%;
    }
}
     }
 }
}
</style>