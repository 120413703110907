<template>
  <div class="main" :class="show ? 'h5' : ''">
        <!-- <mtop /> -->
      <!-- <div class="stop">
        <div @click="toPage('/User')">
            <img src="../assets/img/back.png" alt="" srcset="" class="ico">
        </div>
        <div class="title">{{$t('m.fasong')}}</div>
    </div>   -->


				<div class="nt-title">
      <img src="../assets/img/nt_logo.png" alt="" class="logo">
     <span class="number">{{ ntNumber }}</span>
        </div>

    <div class="navtab">
         <div class="stitle">
        {{$t('m.jieshoudizhi')}}
      </div>
    </div>    
    <div class="line"></div>
    <div class="content">   
        <div class="box">
        <input type="text" class="input" v-model="address" :placeholder="$t('m.qingshurushoukuandizhi')">
        </div>
    </div>
    <div class="navtab">
         <div class="stitle">
        接收数量
      </div>
    </div>    
    <div class="line"></div>
    <div class="content">   
        <div class="box">
        <input type="text" class="input" v-model="ntVal" placeholder="请输入数量">
        </div>
    </div>
 <div class="bottomfilex">
     <van-button
          :loading="loading||uniLoading"
          :disabled="ntVal<=0||!address"
          type="primary"
          @click="toSend"
          class="btn"
          loading-text="Loading..."
        >
         {{$t('m.queren')}}</van-button
        >
    </div>
  
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import {walletOfOwner,ImgV1,ImgV2,ImgV3,ImgV4,getChildren,SendNft,sendTrans,getNoce  } from "@/api/Public";
import transfer from '../api/trans'
import {getNtbalanceOf} from '../api/Public'
export default {
  data() {
    return {
      email: "",
      ntNumber:0,
      show: false,
      active: 0,
      ntVal:'',
        list: [[],[],[]],
        page: 1,
        finished: false,
        loading: false,
        tshow: false,
        plandata: {},
        refreshing: false,
        mynum: 0,
      nftImg: ImgV1,
      cur: 0,
      buynum: 0,
      address: '',
      ids:0,
      uniLoading:false,
      base: [
           { id:0,
              name:  this.$t('m.v1nft'),
          img: ImgV1,
              num:0
            },
            { id:1,
              name:   this.$t('m.v2nft'),
              img: ImgV2,
              num:0
            },
          {id:2,
              name:  this.$t('m.v3nft'),
            img: ImgV3,
              num:0
            },
          {id:3,
              name: this.$t('m.v4nft'),
            img: ImgV4,
              num:0
          }
      ],
      acdata: '',
         buynum:0,
    };
    },
    components: {
    mtop,
        mbottom
  },
  created() {
    this.ids=this.$route.query.id||0
    this.acdata = this.base[this.$route.query.id||0]
       console.log(this.acdata);

     
    },
    filters: {
        yincang(val) {
            if (val) {
        return val.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
         },
  },
  mounted() {
    window.ntSends=(e)=>{
        this.loading=false
      this.handleSends(e)
    }
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
    this.getUserNt()
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
      },
     "$store.getters.userWallet": function () {
      
    },
  },
    computed: {
      cansend() {
        if (this.address && this.buynum > 0) {
          if (this.address.toUpperCase() == this.$store.getters.userWallet.toUpperCase()) { 
            return true
          } else {
            return false;
          }        
      } else {
        return true;
      }
    },
  },
  methods: {
   async getUserNt(){
     await getNtbalanceOf(this.$store.getters.userWallet).then(res => {
        console.log(res)
        if(res.success){
          this.ntNumber=res.res
        }
      })
    },
    async handleSends(e){
        this.uniLoading=true
      const res= await transfer.transferToken(this.address,this.ntVal,e)
      if(res.success){
        this.$notify({ message: '转账成功', type: "success" });  
        this.address=''
        this.ntVal=''        
        await this.getUserNt()  
      }else{
        this.$notify({ message: '转账失败' }); 
      }
      this.uniLoading=false
    },
    toSend(){
        this.loading=true
        uni.postMessage({
        data: {
          accounts:this.$store.getters.userWallet,
          key: true,
        
        }
      })
    },
   
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
 
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
.main {  
   background: #ffffff;
   padding-top:50px;
   .sheader{
    width: 686px;
height: 280px;
border-radius: 8px;
border: 2px solid #EEEEEE;
box-sizing: border-box;
      padding: 0 32px;
      margin: 0 auto;
    .haerbox{
          width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;      
        margin-top: 28px;
      .nftimg{
        position: relative;
        .her{
             width: 53px;
            height: 60px;
        }
        .nft{
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }
        .num{
            position: absolute;
            right: -60px;
            bottom: 0;
            font-size: 28px;
font-weight: 500;
color: #999999;
line-height: 40px;
letter-spacing: 2px;
        }
      }
      .f14{
        flex: 1;
      }
      .name{
        font-size: 32px;
font-weight: 500;
color: #181818;
line-height: 44px;
text-align: left;
margin-left: 20px;
      }
      .dec{
        font-size: 28px;
font-weight: 400;
color: #999999;
line-height: 40px;
text-align: left;
margin-left: 20px;
      }
      .snum{
        width: 204px;
height: 60px;
border-radius: 8px;
border: 2px solid #009270;
font-size: 32px;
font-weight: 400;
color: #009270;
line-height: 60px;
margin: 10px auto 48px;
      }
        .btn{
      width: 336px;
height: 80px;
background: #009270;
border-radius: 4px;
border: 2px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
    }
  
   }
   .bg{
    background: #F7F7F7;
   }
   .navtab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 42px;    
        border-radius: 48px 48px 0 0;
    background: #ffffff;
     .stitle{
            text-align: left;
      font-size: 28px;
font-weight: 600;
color: #333333;
line-height: 34px;
margin: 18px 32px 22px;
    }
    .item{
        font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
flex: 1;
line-height: 40px;
padding-bottom: 10px;
    }
    .act{
        color: #181818;
        position: relative;
    }
    .act::after{
        position: absolute;
        content: '';
        bottom: -1px;
        width: 48px;
height: 4px;
background: #009270;
left: 50%;
transform: translateX(-50%);
    }
   }
   .line{
    width: 100%;
    height: 1px;
background: #F7F7F7;
   }
    .stop{
        position: relative;
        width: 100%;
        height: 100px;
        .ico{
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 22px;
            transform: translateY(-50%);
        }
        .title{
            text-align: center;
            font-size: 32px;
font-weight: 400;
color: #333333;
line-height: 100px;
        }
    }
.content{

.box{
       width: 686px;
height: 132px;
background: #F7F7F7;
border-radius: 8px;
margin: 0 auto; 
padding: 32px;
box-sizing: border-box; 
.input{
    border: none;
    height: 100% ;
    width: 100%;
    background: transparent;
    color: #333333;
    font-size: 28px;
    font-weight: 400;
} 
}
}

 .fuzhi {
        background: transparent;
        border: none;
      }
       .noData {
      min-height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ntxt {
        line-height: 44px;
        font-size: 26px;
        color: #232323;
        font-weight: 600;
      }
      img{
        opacity: 0.5;
    
      }
      .image {
        width: 486px;
        margin: 30px auto;
        opacity: 0.5;
      }
    }
    .rule {
    width: 100%;
    padding: 50px 32px 60px;
    box-sizing: border-box;
    .card {
      width: auto;
      margin: 0 auto;
      text-align: left;
      font-size: 26px;
      .p {
        width: auto;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #556370;
        line-height: 42px;
      }
    }
  }
  .bottomfilex{
      position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
       background: #ffffff;
    box-shadow: inset 0px 1px 0px 0px rgba(0,0,0,0.25);
backdrop-filter: blur(10px);
    padding: 32px 0;
    width: 100%;
    .btn{
         width: 686px;
height: 80px;
background: #009270;
border-radius: 4px;
border: 2px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
  }
}
.nt-title{
  background: #F8F8F8;
  height: 155px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:0 35px;
}
.logo{
  width: 80px;
  height: 80px;
}

.number{
  font-size: 40px;
  font-weight: 600;
}
</style>