import * as ethers from 'ethers'
let rpc_link='https://greatest-sparkling-yard.bsc.quiknode.pro/8d4444ca8342fc7fe70c59bc37e5d5cc107ebd80/'
import ntAbi from './nt.json'
import {failResult,successResult} from './Public'
const  contractAddress ='0x08611bb771B3cAD24fE244b5D55D41Ea015c756d'
const provider = new ethers.providers.JsonRpcProvider(rpc_link);



const transferToken=async(address,value,key)=> {
        try {   
            const toAddress = address;
            const wallet =await new ethers.Wallet(key, provider);
    const tokenContract =await new ethers.Contract(contractAddress,ntAbi, wallet);
    // 获取当前网络的推荐 Gas 价格
    const gasPrice = await provider.getGasPrice();

    let amounts = ethers.utils.parseUnits(value,18);
            const tx = await tokenContract.transfer(toAddress, amounts,{
                gasPrice: gasPrice
            });
            const receipt = await tx.wait();
            return successResult(receipt)
        } catch (error) {
            console.log("error",error)
            return failResult(error)
        }
   

}


export default {
    transferToken
}
