<template>
  <van-popup
    v-model="shows"
    round
    @close="close"
    :close-on-click-overlay="false"
    :closeable="!loading"
  >
    <div class="popup">
      <div class="stop">
      <div class="title">{{$t('m.zzs')}}</div>
      </div>
      <div class="box">
        <div class="item">
          <div class="right">
            <input type="text"  v-model="detail" :placeholder="$t('m.qtxdz')">
          </div>
        </div>
      </div>
      <div class="smbottom">
         <van-button
          :loading="loading"
          type="primary"
          @click="close"
          class="btn qbtn"
        >
          {{$t('m.qx')}}</van-button
        >
        <van-button
          :loading="loading||uniLoading"
          type="primary"
          @click="Upadata"
          class="btn"
          loading-text="Loading..."
        >
          {{$t('m.qd')}}</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
import {Transfer,getToken,getNoce } from "@/api/Public";
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      myFht: 0,
      myTrx: 0,
      shows: false,
      free: 0,
      value: 75,
      active: 1,
      sqnum: 0,
      reshash:'',
      resID: {
        hase:''
      },
      task: "",
      hase: '',
      issq: false,
      currentDate:new Date(),
      formTime: '',
      toTime:'',
      tshow: false,
      tshows: false,
      detail:'',
      uniLoading:false
      
    };
  },
  mounted(){
    window.toSend=(info)=>{
     this.handleSend(info)
    }
  },
  watch: {
    show(val) {
      this.shows = val;
      if (val) {
         this.detail=''
      }
    },
  },
  computed:{
    ftime() {
         var dd = new Date();
            var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      return y+ '-' + m + '-' + d
      },
       // 登陆类型
    type() {
      if (this.$store.getters.ltype) {
        return this.$store.getters.ltype
      } else {
        return "1";
      }
    },
 },
  methods: {
    async handleSend(info){
      this.uniLoading=true
     const res= await sendTrans(info)
     if (res.success) {
      this.$emit("updata", true);
                this.$notify({
                        message: "Transfer to the success",
                        type: "success",
                    });
            } else {
              this.$notify("Transfer failure !");      
            }
            this.uniLoading=false
    },
    confirm(e) {
    
        var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.toTime = y + '-' + m + '-' + d
      this.tshow=false
     },
    confirms(e) {
         var dd = new Date(e);
              var y = dd.getFullYear();
      var m = dd.getMonth() + 1;//获取当前月份的日期 
      var d = dd.getDate();
             if (d < 10) {
                d = '0' + d;
      }
      console.log(y, m);
      this.formTime = y + '-' + m + '-' + d
      this.tshows=false
     },
    close() {
      this.$emit("tshow", false);
    },
    toclose() {
      this.active=1
      this.$emit("tshow", false);
      this.$emit("updata", true);
    },
    
   async Upadata() {
      sessionStorage.setItem('type',6)
      let resp= await getNoce(this.$store.getters.userWallet)
     if(resp.success){
      uni.postMessage({
        data: {
          noce: resp.res
        
        }
      })
     }
        if (!this.detail) { return }
        this.loading = true
        Transfer(this.$store.getters.userWallet, this.detail,this.type).then(res => {
             this.loading = false
            if (res) {
                this.$emit("updata", true);
                this.$notify({
                        message: "Transfer to the success",
                        type: "success",
                    });
            } else {
              
                }
      })
       
              
    //   this.$post({
    //     url: "/dayReport",
    //     data: {
    //      content : this.detail,   
    //     },
    //     callback: (res) => {
    //       this.$nextTick(function () {
    //         this.loading = false;
    //         if (res.code == 200) {
    //           //  this.active=1
    //           this.$emit("tshow", false);
    //           this.$emit("updata", true);
    //         } else {
    //           this.$notify(res.msg);
    //         }
    //       });
    //     },
    //   });
    },    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.popup {
  width: 686px;
min-height: 345px;
background: #FFFFFF;
border-radius: 4px;

  .stop {
    height: 69px;
//    border-bottom: 1px solid #DCDEE0;
     .title {
    text-align: center;
    margin-bottom: 32px;
    font-size: 32px;
font-weight: 500;
color: #323233;
line-height: 69px;
  }
  }
 
  .box{
    padding: 29px 0 39px;

    .item{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px ;
      .right{
        width: 80%;
        background: #FFFFFF;
        input{
          padding: 0 20px;
          width: 100%;
          box-sizing: border-box;
          height: 80px;
          font-size: 24px;
          color: #030304;
           border: 1px solid #DCDEE0;
          border-radius: 3px;
        }    
      
      }
    }
  }
   .smbottom {
    display: flex;
    align-items: center;
    justify-content: center;
        padding: 12px 0;
      .btn {
    font-size: 32px;
    font-family: $t-mf;
    font-weight: bold;
    color: #ffffff;
    line-height: 46px;
    margin: 10px;
 width: 310px;
height: 88px;
background: #1C9371;
border-radius: 12px;
border: 1px solid #1C9371;
font-weight: 400;
  }
      .qbtn {
        width: 200px;
height: 88px;
font-weight: 400;
     background: #FFFFFF;
border-radius: 12px;
border: 1px solid #1C9371;
color: #1C9371;
    }
  }

//    .smbottom {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//         border-top: 1px solid #DCDEE0;
//         padding: 12px 0;
//       .btn {
//     font-size: 32px;
//     font-family: $t-mf;
//     font-weight: bold;
//     color: #009270;
//     line-height: 46px;
//     margin: 10px;
//  flex: 1;
// height: 43px;
// border-radius: 3px;
// border: none;
// background: #FFFFFF;
//   }
//       .qbtn {
//      background: #FFFFFF;
// border-radius: 3px;
// border: 1px solid #DCDEE0;
// color: #030304;
// border: none;
//     }
//   }
}
</style>
