<template>
  <div class="main" :class="show ? 'h5' : ''">
        <mtop /> 
    <div class="sheader" >
        <div class="haerbox">
          <div class="swtop ">
            <div class="left" @click="changer(il)" :class="ishow?'carousel-containers':''">
              <div class="bg"></div>
              <!-- <van-image
              round
                class="nft"
                :src="base[il].img"
                /> -->
                <img   :src="base[il].img" alt="" srcset=""  class="nft">
               <div class="name">{{base[il].name}}</div>
            </div>
        <div class="nftimg " :class="ishow?'boxbuy':''" >
          <img  :src="acdata.img" alt="" srcset=""  class="nft">
         
        </div>
            <div class="right" @click="changers(is)" :class="ishow?'carousel-container':''"> 
              <div class="bg"></div>
              <img :src="base[is].img" alt="" srcset=""  class="nft">
                <!-- <van-image
                round
                class="nft"
                :src="base[is].img"
                /> -->
             <div class="name">{{base[is].name}}</div></div>
        </div>
        <div class="name">{{acdata.name}} <span class="dw"> {{$t('m.shengyu')}}{{acdata.all}} {{$t('m.mei')}} </span> </div>
        <div class="snum">{{buynum}} / {{acdata.price}}</div>
        </div>        
    </div>




    <!-- <van-sticky :offset-top="0" class="bg"> -->
    <div class="navtab">
         <div class="stitle">
       {{$t('m.xuanzhehechengshuliang')}}
      </div>
    </div>    
    <div class="line"></div>
    <!-- </van-sticky> -->
    <div class="content">   
    
          <div
            v-for="(item, i) in list"
            :key="i"
            class=""
          >
          <div class="item" v-if="acdata.id-item.id>0">
            <div class="left">
                <img :src="item.img" alt="" srcset="" class="nft">
            <div class="name">
              <div class="title">{{ item.name||'V1-NFT' }}</div>
              <div class="time">
                {{$t('m.yynum')}}{{item.num||0}}
              </div>
              </div>
            </div>

            <div class="right">
              <div class="sbtn">
              <van-stepper v-model="item.buynum" :max="item.maxnum" 
              @change="tohcnum(i)" @blur="tohcnum(i)"  input-width="60px" :min="0"  button-size="32px"  class="mint" />
              </div>
            </div>
            </div>
           
          </div>
            <div class="bottomfilex">
           <van-button
                  type="primary"
                  v-if="this.$store.getters.userWallet"
                  class="btn"
                  :disabled="!canhc"
                  :loading-text="$t('m.hechengzhong')"
                  :loading="hcloading||uniLoading"
                  @click="tocarft"
                  >{{$t('m.lijihecheng')}}</van-button
                >
    </div>

           <div class="rule">
        <div class="card">
         <div class="hcbg">BBGen {{$t('m.hechengguize')}}</div>
          <img src="../assets/img/hcbg.png" alt="" class="gzbg" v-if="dqyy=='zh-CN'">
          <img src="../assets/img/hcbgx.png" alt="" class="gzbg" v-else>
      
        </div>
      </div>
      
    </div>
 
    <Tranfer
      :show="tshow"
      :data="plandata"
      @tshow="
        (val) => {
          tshow = val;
        }"
      @updata="
        (val) => {
          tshow = false;
          if(val){this.getmyNum()}
        }
      "
    />
    <mbottom/>
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import Tranfer from "../components/transfer.vue"
import {walletOfOwner,ImgV1,getChildren,ImgV2,ImgV3,ImgV4,toCompose,gettotal,sendTrans,getNoce} from "@/api/Public";
export default {
  data() {
    return {
      email: "",
      show: false,
      uniLoading: false,
      ishow:false,
      active: 0,
        list: [],
      page: 1,
        il: 0,
        i:1,
        is:2,
        finished: false,
        loading: false,
        tshow: false,
        plandata: {},
        refreshing: false,
        mynum: 0,
        nftImg: ImgV1,
      cur: 0,
      base: [
             {id:2,
             name: this.$t('m.v3nft'),
            img: ImgV3,
            all: 200,
              buynum:0,
              price:25,
            num:0
            },
            { id:1,
            name: this.$t('m.v2nft'),
            img: ImgV2,
            all: 500,
            buynum: 0,
            price: 5,
            num:0
            },
       
          {id:3,
             name: this.$t('m.v4nft'),
            img: ImgV4,
            all: 21,
            buynum: 0,
            price:125,
            num:0
          }
      ],
      mylist: [
         { id:0,
              name: this.$t('m.v1nft'),
            img: ImgV1,
            all: 30000,
            buynum: 0,
          price: 1,
              num:0,
              maxnum:0
        },
          
          { id:1,
            name: this.$t('m.v2nft'),
            img: ImgV2,
            all: 500,
            buynum: 0,
            price: 5,
            num:0,
              maxnum:0
            },
            {id:2,
             name: this.$t('m.v3nft'),
            img: ImgV3,
            all: 200,
              buynum:0,
              price:25,
            num:0,
              maxnum:0
            },
          {id:3,
             name: this.$t('m.v4nft'),
            img: ImgV4,
            all: 21,
            buynum: 0,
              price:125,
            num:0,
              maxnum:0
          }
      ],
      acdata: '',
      buynum: 0,
      canhc: false,
         hcloading:false
    };
    },
    components: {
    mtop,
        mbottom,
    Tranfer
  },
    created() {
    
      this.list = this.mylist
      this.acdata = this.base[1]
         let i = setInterval(() => {
      if ((typeof window.ethereum) !== 'undefined') {   
      this.getmyNum();
        clearInterval(i)
    }
}, 1000); 
    },
    filters: {
        yincang(val) {
            if (val) {
        return val.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
         },
  },
  mounted() {
    window.synthesis=(info)=>{
      this.handle(info)
    }
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  computed: {
      dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    },
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
      },
     "$store.getters.userWallet": function () {
      this.getmyNum();
    },
  },
  methods: {
   async handle(info){
    this.uniLoading=true;
    const res= await sendTrans(info)
    if(res.success){
      this.$notify({ message: "合成成功", type: "success" });
              this.getmyNum()
    }else{
      this.$notify({ message: "合成失败", type: "success" });
    }
    this.uniLoading=false;
    },
   async tocarft() { 
      sessionStorage.setItem('type',4)
      let resp= await getNoce(this.$store.getters.userWallet)
     if(resp.success){
      uni.postMessage({
        data: {
          noce: resp.res
        
        }
      })
     }
      if (this.buynum - this.acdata.price > 0) { 
        this.canhc=false        
        return
      }
      if (this.canhc) {
        this.hcloading = true
        if (this.acdata.id == 1) {
          let ids = [0]
          let num = [5]
          let tagid = [this.acdata.id]
         this.toidht(ids, num, tagid)
        }
        if (this.acdata.id == 2) {          
          let ids = this.list[1].buynum>0?this.list[0].buynum>0?[0,1]:[1]:[0]
          let num =  this.list[1].buynum>0?this.list[0].buynum>0?[this.list[0].buynum,this.list[1].buynum]:[this.list[1].buynum]:[this.list[0].buynum]
          let tagid = [this.acdata.id]
          console.log(ids,num,tagid);
         this.toidht(ids, num, tagid)
        }
         if (this.acdata.id == 3) {
           let ids = this.list[2].buynum > 0 ?
             (this.list[1].buynum > 0 ? this.list[0].buynum > 0 ? [0, 1, 2] : [1, 2] : this.list[0].buynum > 0 ? [0, 2] : [2]) :
             this.list[1].buynum > 0 ? (this.list[0].buynum > 0 ? [0,1] : [1]) : this.list[0].buynum > 0 ? [0] : []
           let a0 = this.list[0].buynum
           let a1 = this.list[1].buynum
           let a2=this.list[2].buynum         
          //  this.list[2].buynum>0?this.list[1].buynum>0?this.list[0].buynum>0?[0,1,2]:[1,2]:[0,2]:[0,1]
           let num = this.list[2].buynum > 0 ? (this.list[1].buynum > 0 ? this.list[0].buynum > 0 ?
             [a0, a1, a2] : [a1, a2] : this.list[0].buynum > 0 ? [a0, a2] : [a2]) :
             this.list[1].buynum > 0 ?( this.list[0].buynum > 0 ? [a0,a1] : [a1] ): this.list[0].buynum > 0 ? [a0] : []
           let tagid = [this.acdata.id]
          
         console.log(ids,num,tagid);
         this.toidht(ids, num, tagid)
        }
      }
    },
    toidht(ids, num, tagid) { 
        toCompose(ids, num, tagid, this.$store.getters.userWallet).then(res => {
            this.hcloading = false
            if (res) {
              this.$notify({ message: "合成成功", type: "success" });
              this.getmyNum()
            }
          })
    },
    togetNum() { 
      this.base.map((el, i) => {
         gettotal(el.id).then(res => {
        this.$set(this.base[i],'all',el.all-res)
      })
      })
     
    },

    tohcnum() {      
      if (this.acdata.id == 1) {
        this.buynum = this.list[0].buynum  
         if (this.buynum >= this.acdata.price) {
           this.canhc = true
         let num =this.list[0].num-5>0?5:this.list[0].num
          this.$set(this.list[0],'maxnum',num)
         } else {
           this.canhc = false
         this.$set(this.list[0],'maxnum',this.list[0].num)
      }     
      }
      if (this.acdata.id == 2) {
        this.buynum = this.list[0].buynum + this.list[1].buynum * this.list[1].price
           if (this.buynum >= this.acdata.price) {
             this.canhc = true
              let num =this.list[0].num-25>0?25:this.list[0].num
        //  let num =this.list[0].num-this.list[0].buynum>0?this.list[0].buynum:this.list[0].num
             this.$set(this.list[0], 'maxnum', num)
          let num1=this.list[1].num-this.list[1].buynum>0?this.list[1].buynum:this.list[1].num
          this.$set(this.list[1],'maxnum',num1)
           } else {
             this.canhc = false
             this.$set(this.list[0], 'maxnum', this.list[0].num)
          this.$set(this.list[1],'maxnum',this.list[1].num)
      }   
      }
      if (this.acdata.id == 3) {
          this.buynum = this.list[0].buynum + this.list[1].buynum * this.list[1].price+this.list[2].buynum * this.list[2].price
           if (this.buynum >= this.acdata.price) {
             this.canhc = true
            //  let num = this.list[0].num - this.list[0].buynum > 0 ? this.list[0].buynum : this.list[0].num
              let num =this.list[0].num-125>0?125:this.list[0].num
             this.$set(this.list[0], 'maxnum', num)
           let num1=this.list[1].num-this.list[1].buynum>0?this.list[1].buynum:this.list[1].num
             this.$set(this.list[1], 'maxnum', num1)
              let num2=this.list[2].num-this.list[2].buynum>0?this.list[2].buynum:this.list[2].num
             this.$set(this.list[2],'maxnum',num2)
           } else {
             this.canhc = false
             this.$set(this.list[0], 'maxnum', this.list[0].num)
             this.$set(this.list[1], 'maxnum', this.list[1].num)
             this.$set(this.list[2],'maxnum',this.list[2].num)
      } 
      }
      
     
     },
    changer(i) {
            this.ishow = true
      setTimeout(() => {  
        this.tohcnum()  
      this.ishow=false   
      }, 100); 
      if (i == 0) {
        this.is = 1        
        this.i=i
        this.il = 2
      }
       if (i == 1) {
        this.is = 2        
        this.i=i
        this.il = 0
      }
       if (i == 2) {
        this.is = 0        
        this.i=i
        this.il = 1
      }
      this.acdata=this.base[this.i]
    },
    changers(i) {
      this.ishow = true
      setTimeout(() => { 
        this.tohcnum()     
      this.ishow=false   
      }, 100); 
      if (i == 0) {
        this.is = 1
        this.il = 2
        this.i=i
      }
       if (i == 1) {
        this.is = 2
        this.il = 0
        this.i=i
      }
       if (i == 2) {
        this.is = 0
        this.il = 1
        this.i=i
      }
      this.acdata=this.base[this.i]
     },
          onCopy() {
      this.$toast({ message: this.$i18n.t("m.fzcg"), icon:'none'});
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
        getmyNum() { 
             this.mylist.map((el, i) => {
             walletOfOwner(this.$store.getters.userWallet,el.id).then(res => {
               this.$set(this.list[i], 'num', res)
                 this.$set(this.list[i],'maxnum',res)
                this.onLoad()
            })
             })
            this.togetNum()
        },
        getNum(address, i) { 
            walletOfOwner(address).then(res => {
                this.$set(this.list[this.active][i],'address',address)
                this.$set(this.list[this.active][i], 'num', res)
                this.$forceUpdate()
                 this.loading = false;
            })
        },
        getList() { 

        },
        toshow() { 
            if (this.mynum > 0) {
                if (this.tshow) { this.tshow = false }
                setTimeout(() => {
                    this.tshow = true
                }, 100);
            } else {
                this.getmyNum()
            }
        },
        onLoad() { 
            //    this.loading = false;
            // this.finished = true;           
         this.list = this.mylist
         this.refreshing=false
        },
         onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
        },
        togetChaildren() { 
            this.list[2]=[]
            getChildren(this.$store.getters.userWallet, this.type).then(res => {  
                  if (res.length > 0) {                   
                      res.map((el, i) => {
                         this.list[2].push({address:'',num:''})
                          this.getNum(el, i)
                      })
                    }
                })
        },
        tochanger(e) {
            this.active = e
            if (e == 2) {
                 this.loading = true;
                 setTimeout(() => {
                    this.togetChaildren()          
                 }, 100);
                   
            }
         },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    open(e) {
      window.open(e);
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    to(url) {
      window.open(url);
    },
    toPage(e) {
      if (e == this.$route.path) {
        return;
      }
      this.$router.push({ path: e });
    },
    ding() {
      if (this.email) {
        const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
        if (reg.test(this.email)) {
          this.$toast({
            message: "Subscribe to the success",
            type: "success",
          });
          this.email = "";
        }
      } else {
        this.$notify("Please enter your email number");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
.main {  
   background: #ffffff;
   .sheader{
    width: 100%;
      background: url("../assets/img/bg.png") no-repeat top center;
    background-size: cover;
    .haerbox{
          width: 100%;
      height: 100%;
      min-height: 8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 129px;
      .swtop{
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        padding: 32px;
        margin: 0 auto;
        box-sizing: border-box;
        .left{
          position: absolute;
          left: 32px;
            width: 300px;
            height: 300px;
          background: #ffffff;
          z-index: 0;
           border-radius: 100%;
           top: 50%;
           transform:  translateY(-50%);
           .bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 300px;
            height: 300px;
            background: #ffffff;
            opacity: 0.5;
             border-radius: 100%;
          }
          .nft{
            width: 300px;
            height: 300px;
             border-radius: 100%;
          }
 .name{
        font-size: 24px;
        font-weight: 400;
color: #ffffff;
line-height: 44px;
margin: 14px 0 0px;
padding-left: 26px;
      }
        }
        .right{
           position: absolute;
          right: 32px;
          z-index: 0;
             width: 300px;
            height: 300px;
          background: #ffffff;
           border-radius: 100%;
             top: 50%;
           transform:  translateY(-50%);
          .bg{
            position: absolute;
            top: 0;
            left: 0;
             width: 300px;
            height: 300px;
            background: #ffffff;
            opacity: 0.5;
             border-radius: 100%;
          }
          .nft{
            width: 300px;
            height: 300px;
             border-radius: 100%;
          }
           .name{
        font-size: 24px;
font-weight: 400;
color: #ffffff;
line-height: 44px;
margin: 14px 0 0px;
padding-right: 26px;

      }
        }
      }
      .nftimg{
        position: relative;
        margin-top: -28px;
        z-index: 1;
        
        .nft{
            width: 400px;
            height: 400px;
            border-radius: 100%;
            box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
        }
        .num{
            position: absolute;
            right: -60px;
            bottom: 0;
            font-size: 28px;
font-weight: 500;
color: #ffffff;
line-height: 40px;
letter-spacing: 2px;
        }
      }
      .name{
        font-size: 32px;
font-weight: 500;
color: #ffffff;
line-height: 44px;
margin: 24px 0 0px;
.dw{
  font-size: 24px;
font-weight: 400;
color: #ffffff;
line-height: 34px;
}
      }
      .dec{
        font-size: 28px;
font-weight: 400;
color: #999999;
line-height: 40px;
      }
      .snum{
        width: 204px;
height: 60px;
border-radius: 8px;
border: 1px solid #ffffff;
font-size: 32px;
font-weight: 400;
color: #ffffff;
line-height: 60px;
margin: 10px auto 48px;
      }
        .btn{
      width: 336px;
height: 80px;
background: #ffffff;
border-radius: 4px;
border: 1px solid #ffffff;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
    }
  
   }
   .bg{
    background: #F7F7F7;
   }
   .navtab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 42px;    
        border-radius: 48px 48px 0 0;
    background: #ffffff;
     .stitle{
            text-align: left;
      font-size: 28px;
font-weight: 600;
color: #333333;
line-height: 34px;
margin: 18px 32px 22px;
    }
    .item{
        font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
flex: 1;
line-height: 40px;
padding-bottom: 10px;
    }
    .act{
        color: #181818;
        position: relative;
    }
    .act::after{
        position: absolute;
        content: '';
        bottom: -1px;
        width: 48px;
height: 4px;
background: #009270;
left: 50%;
transform: translateX(-50%);
    }
   }
   .line{
    width: 100%;
    height: 1px;
background: #F7F7F7;
   }
    .stop{
        position: relative;
        width: 100%;
        height: 100px;
        .ico{
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 22px;
            transform: translateY(-50%);
        }
        .title{
            text-align: center;
            font-size: 32px;
font-weight: 400;
color: #333333;
line-height: 100px;
        }
    }
.content{
    border-radius: 48px 48px 0 0;
    // overflow-y: auto;
   
    .item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px;
            border-bottom: 1px solid #F0F0F0;
        .left{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .nft{
                width: 100px ;
                height: 100px;
                margin: 0 20px;
                border-radius: 8px;
            }
            .title{
                font-size: 32px;
font-weight: 500;
color: #333333;
line-height: 44px;
text-align: left;
            }
            .time{
                font-size: 28px;
                text-align: left;
font-weight: 400;
color: #999999;
line-height: 40px;
            }
        }
        .right{
            font-size: 28px;
font-weight: 500;
color: #999999;
line-height: 40px;
letter-spacing: 2px;
        }

        .sleft{
            display: flex;
            flex-direction: column;
            text-align: left;
            .title{
                font-size: 28px;
font-weight: 400;
color: #181818;
line-height: 40px;
            }
            .address{
                display: flex;
                align-items: center;
                font-size: 28px;
                .ic{
                    width:22px ;
                    height: 22px;
                    margin: 0 10px;
                }
            }
        }
          .sright{
            display: flex;
            flex-direction: column;
            text-align: right;
        font-size: 28px;
font-weight: 400;
color: #181818;
.snum{
    font-weight: 500;
}
.time{
    font-size: 28px;
}
        }
    }
}
 .fuzhi {
        background: transparent;
        border: none;
      }
       .noData {
      min-height: 50vh;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ntxt {
        line-height: 44px;
        font-size: 26px;
        color: #232323;
        font-weight: 600;
      }
      img{
        opacity: 0.5;
    
      }
      .image {
        width: 486px;
        margin: 30px auto;
        opacity: 0.5;
      }
    }
    .rule {
    width: 100%;
    padding: 50px 32px 60px;
    box-sizing: border-box;
    .card {
      width: auto;
      margin: 0 auto;
      text-align: left;
      font-size: 26px;
      .hcbg{
        background: url('../assets/img/hcgzbg.png') no-repeat;
        background-size: 100% 100%;
        height: 84px;
        width: 100%;
        font-size: 28px;
font-weight: 600;
color: #009270;
line-height: 34px;
display: flex;
align-items: center;
padding-left: 60px;
box-sizing: border-box;
      }
      .gzbg{
        width: 100%;
        height: 100%;
        margin-top: 22px;
      }
      .title{
        font-size: 28px;
font-weight: 400;
color: #121315;
line-height: 40px;
margin-top: 32px;
margin-bottom: 12px;
      }
      .p {
        width: auto;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #556370;
        line-height: 42px;
      }
    }
  }
  .bottomfilex{
    .btn{
         width: 686px;
height: 80px;
background: #009270;
border-radius: 4px;
border: 1px solid #1B9070;  
font-size: 32px;
font-weight: 500;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
margin: 0px auto;
    }
  }







@keyframes carousel {
  0% {
    transform: translate(0,-50%);
  }
  100% {
    transform: translate(-100%,-50%);
  }
}
@keyframes carousels {
  0% {
    transform: translate(0,-50%);
  }
  100% {
    transform: translate(100%,-50%);
  }
}
@keyframes boxbuy {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.carousel-container {
  // display: flex;
  animation: carousel 0.5s ;
}
.carousel-containers {
  // display: flex;
  animation: carousels 0.5s ;
}

.boxbuy {
  // display: flex;
  animation: boxbuy 0.5s ;
}




}
</style>